<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center mt-2">
      <div class="col-12">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Detalhes do Booking</h5>

            <div class="row">

              <!-- Esquerda -->
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Situação</small>
                    <div class="text-capitalize" :style="{'color': color}">
                      {{ status }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Operação</small>
                    <div class="text-capitalize">
                      {{ operacao }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Origem</small>
                    <div class="text-capitalize">
                      {{ booking.id_porto_origem ? booking.id_porto_origem.nome : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Destino</small>
                    <div class="text-capitalize">
                      {{ booking.id_porto_destino ? booking.id_porto_destino.nome : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Mercadoria</small>
                    <div class="text-capitalize">
                      {{ booking.id_mercadoria ? booking.id_mercadoria.nome : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Equipamentos</small>
                    <div v-if="booking.equipamentos">
                      <div v-for="equip in booking.equipamentos" :key="equip.id">
                        {{ equip.quantidade }} x {{ equip.idequipamento_maritimo.descricao }}
                      </div>
                    </div>
                    <div v-else>{{ '-' }}</div>
                  </div>
                </div>
              </div>

              <!-- Centro -->
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Armador</small>
                    <div class="text-capitalize">
                      {{ booking.idcompanhia_transporte ? booking.idcompanhia_transporte.id_pessoa.nome : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Navio</small>
                    <div class="text-capitalize">
                      {{ booking.id_navio ? booking.id_navio.nome : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Cliente</small>
                    <div class="text-capitalize">
                      {{ booking.id_cliente ? booking.id_cliente.idpessoa.nome : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Processo</small>
                    <div class="text-capitalize">
                      {{ booking.id_logistica_house ? booking.id_logistica_house.numero_processo : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Referência Interna</small>
                    <div class="text-capitalize">
                      {{ booking.numero ? booking.numero : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Contrato</small>
                    <div class="text-capitalize">
                      {{ booking.idcontrato_frete ? booking.idcontrato_frete.numero_contrato : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0">
                    <small class="custom-label">Semana</small>
                    <div class="text-capitalize">
                      {{ booking.semana ? booking.semana : '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Direita -->
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Vendedor</small>
                    <div class="text-capitalize">
                      {{ booking.id_vendedor ? booking.id_vendedor.id_pessoa.nome : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Solicitante</small>
                    <div class="text-capitalize">
                      {{ booking.id_solicitante ? booking.id_solicitante.id_pessoa.nome : '-' }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">ETD</small>
                    <div class="text-capitalize">
                      {{ formatDateTime(booking.etd) }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Dead Line</small>
                    <div class="text-capitalize">
                      {{ formatDateTime(booking.dead_line) }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Criação</small>
                    <div class="text-capitalize">
                      {{ formatDateTime(booking.data_criacao) }}
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-12 px-0 pb-3">
                    <small class="custom-label">Multa</small>
                    <div class="text-capitalize">
                      {{ booking.possui_multa ? $util.formatToPrice(booking.valor_multa) : 'Não possui' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Observação -->
            <hr v-if="booking.observacao">
            <div class="row" v-if="booking.observacao">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-12 px-0">
                    <small class="custom-label">Observação</small>
                    <div class="text-capitalize">
                      {{ booking.observacao }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr>

            <!-- Botões de ação -->
            <div class="row">
              <div class="col-12 px-0">
                <a v-if="canEdit" class="btn btn-sm btn-outline-light mx-1 pull-right"
                  v-bind:href="$util.mountLink('BookingEdit', this.booking)">Editar</a>

                <button v-if="canTakeOrThrow" class="btn btn-sm btn-outline-light btn-a-color mx-1 pull-right" @click="toogleBooking()">
                  <span v-if="isTheSeller">Devolver</span>
                  <span v-if="isAvailable">Utilizar</span>
                </button>

                <!-- <button type="button" class="btn btn-link btn-sm mx-1 pull-right" @click="showOptions">
                  Opções
                </button> -->

                <button type="button" class="btn btn-sm btn-link btn-a-color mx-1 pull-right" @click="onCancel">
                  Listagem
                </button>

                <!-- <button type="button" class="btn btn-link btn-sm mx-1 btn-a-color pull-right" @click="mostra">
                  Console
                </button> -->

                <button type="button" class="btn btn-link btn-sm btn-a-color mx-1 pull-left" @click="onDelete">
                  Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Mensagens -->
      <div v-if="booking.comentarios" class="col-12">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Mensagens</h5>

            <hr v-if="hasMessages">

            <div class="row" v-if="hasMessages">
              <div class="col-12 px-0 py-2" v-for="comentario in booking.comentarios" :key="comentario.id">
                <small style="color:grey">{{ comentario.id_proprietario.id_pessoa.nome }}, em {{ formatDateTime(comentario.data_criacao) }}</small>
                <div>{{ comentario.texto }}</div>
              </div>
            </div>

            <hr>

            <!-- Nova mensagem -->
            <div class="col-md-12 my-2">
              <label class="my-1">Nova mensagem</label>
              <custom-textarea
                v-model="newMessage"
                name="newMessage"
                type="text"
                rootClassName="md-form my-0"
                inputClassName="md-form-control mt-0"
                :rows="2"
                v-validate="{ required: false }"
                :error="errors.first('newMessage')">
              </custom-textarea>
              <button type="button" class="btn btn-sm btn-outline-light btn-a-color pull-right"
                :disabled="!newMessage" @click="onSaveMessage">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Assinantes -->
      <div class="col-md-6">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Assinantes</h5>
            <hr>

            <div class="row" v-for="assinante in booking.assinantes" :key="assinante.id">
              <div class="col-12 px-1">
                <small>{{ assinante.id_user.name }} ({{ assinante.id_user.email }})</small>
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-12 px-0">
                  <a v-if="canEdit" class="btn btn-sm btn-outline-light pull-right mx-1"
                    v-bind:href="$util.mountLink('BookingFollowers', this.booking)">Editar</a>

                <button class="btn btn-sm btn-outline-light btn-a-color pull-right mx-1" @click="toogleSubscribe()">
                  <span v-if="isFollowing">Deixar de acompanhar</span>
                  <span v-if="!isFollowing">Acompanhar</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Histórico de alterações de situação -->
      <div class="col-md-6">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-1 mt-1">Alterações de situação</h5>
            <hr>
            <div v-if="booking.historico_situacao && booking.historico_situacao.length">
              <div class="row" v-for="alteracao in booking.historico_situacao" :key="alteracao.id">
                <div class="col-12 p-1">
                  <small>De <span :style="{'color': statusColor(alteracao.situacao_anterior)}">
                    {{ fullStatus(alteracao.situacao_anterior) }}
                  </span> para <span :style="{'color': statusColor(alteracao.situacao_atualizada)}">
                    {{ fullStatus(alteracao.situacao_atualizada) }}
                  </span>, por {{ alteracao.responsavel.name }}, em {{ formatDateTime(alteracao.data) }}</small>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-12 p-1">
                  <span>Sem registros</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Merge -->
      <div v-if="mergeOptions.length && canEdit" class="col-md-6">
      <!-- <div v-if="canEdit" class="col-md-6 pull-right"> -->
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Opções de mesclagem</h5>
            <hr>

            <div class="row" v-for="record in mergeOptions" :key="record.id">
              <div class="col-11 px-1">
                <a v-bind:href="$util.mountLink('BookingView', record)">
                  Id: {{ record.id }} - Criado em: {{ $util.formatDateTime(record.data_criacao) }}</a>
              </div>
              <div class="col-1 pull-right">
                <input v-model="record.selected" value="1" type="checkbox" :id="checkboxId(record)">
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-12 px-0">
                <button :disabled="!countMergeSelected" class="btn btn-sm btn-outline-light pull-right mx-1" @click="mergeSelected()">
                  <span>Mesclar registros</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Slot vazio -->
      <div v-if="mergeOptions.length && canEdit" class="col-md-6 pull-right">
        <div class="card card-fullheight">
          <div class="card-body p-3">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
// import Details from '@/views/Booking/Detail.vue'
// import OptionsList from './OptionsList.vue'
import ApplyForm from './ApplyForm.vue'

// Services
import BookingService from '@/services/BookingService'
// import CustomerService from '@/services/CustomerService'
// import EmployeeService from '@/services/EmployeeService'

export default {
  name: 'BookingDetail',
  components: {
    Loading,
    CustomTextarea
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      booking: {
        armador: null,
        cliente: null,
        vendedor: null,
        solicitante: null,
        processo: null,
        tipoOperacao: {
          id: 2,
          name: 'IMPORT'
        },
        origem: null,
        destino: null,
        navio: null,
        mercadoria: null,
        equipamentos: [],
        numero: null,
        situacao: {
          id: 0,
          label: 'Solicitar'
        },
        etd: null,
        ddl: null,
        possuiMulta: null,
        valorMulta: null,
        observacao: null,
        dataCriacao: null,
        dataSolicitacao: null,
        send_email: 0,
        contrato: null,
        semana: null
      },
      subscribers: [],
      newMessage: null,
      colors: [
        '#ff0000', /* solicitar:  vermelho */
        '#f1ba26', /* solicitado: amarelo */
        '#ff0000', /* rejeitado:  vermelho */
        '#5ec65f', /* disponível: verde */
        '#5ec65f', /* confirmado: verde */
        '#ababab', /* utilizando: cinza */
        '#ababab', /* utilizado:  cinza */
        '#ababab', /* cancelado:   cinza */
        '#ababab' /* cancelado:   cinza */
      ],
      statusOptions: [],
      operationOptions: [
        'Exportação',
        'Importação'
      ],
      mergeOptions: []
    }
  },
  // Apply filters to local filter
  beforeMount () {
    this.statusFetchOptions()
    this.loadBookingInfo()
  },
  methods: {
    mostra () {
    },
    toogleSubscribe () {
      let _this = this
      _this.subsLoaded = false
      let formData = new FormData()
      formData.append('booking', this.booking.id)

      BookingService.toogleSubscribe(formData).then(res => {
        let subs = res.data.data.subscribers.map(x => (
          x.id_user.id
        ))
        _this.subscribers = subs
        this.booking.assinantes = res.data.data.subscribers
        _this.subsLoaded = true
      })
    },
    loadBookingInfo () {
      this.isLoading = true
      let id = this.$route.params.id

      BookingService.getBooking(id).then(res => {
        this.booking = res.data.data

        let subs = res.data.data.assinantes.map(x => (
          x.id_user.id
        ))
        this.subscribers = subs
        this.loadMergeOptions()
        this.isLoading = false
      })
    },
    statusFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        BookingService.getStatusOptions(filters).then(res => {
          _this.statusOptions = res.data.data
          resolve(_this.statusOptions)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'BookingIndex' })
    },
    fullStatus (value) {
      return this.statusOptions.length ? this.statusOptions[value] : 'não informado'
    },
    statusColor (value) {
      return parseInt(value) ? this.colors[value] : '#000000'
    },
    formatDateTime (date) {
      return this.$util.formatDateTime(date, 'pt-BR', ' - ')
    },
    onDelete () {
      this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja excluir este booking?',
        'warning', 'Sim', 'Não', (e) => this.deleteBooking(this.booking.id)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Registro excluído com sucesso!')
        } else {
          this.$alertSwal.toast('Não foi possível excluir o registro!', 'error')
        }

        this.$router.push({ name: 'BookingIndex' })
      })
    },
    async deleteBooking (id) {
      let _this = this
      _this.isLoading = true
      await BookingService.deleteBooking(id).then(res => {
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    onUpdateStatus (booking, newStatus) {
      let currentStatusLabel = this.statusOptions[booking.situacao]
      let newStatusLabel = this.statusOptions[newStatus]
      let message = `Confirma a alteração de situação de "${currentStatusLabel}" para "${newStatusLabel}"?`

      this.$alertSwal.modalConfirm('', message,
        'warning', 'Sim', 'Não', (e) => this.updateBookingStatus(booking.id, newStatus)).then((res) => {
        if (res.value.success) {
          this.$alertSwal.toast(res.value.message)
        } else {
          let message = `Não foi possível alterar o registro!<br/> ${res.value.message}`
          this.$alertSwal.toast(message, 'error')
        }
        this.$refs.vuetable.refresh()
      })
    },
    async updateBookingStatus (bookingId, newStatusKey) {
      let _this = this
      _this.isLoading = true

      let data = {
        id: bookingId,
        situacao: newStatusKey
      }

      let resp = await BookingService.updateBookingStatus(data).then(res => {
        _this.isLoading = false
        return {
          success: true,
          message: res.data.message,
          record: res.data.data
        }
      }).catch((e) => {
        _this.isLoading = false
        return {
          success: false,
          message: e.message
        }
      })

      return resp
    },
    toogleBooking () {
      if (this.isAvailable) {
        this.onApply(this.booking.id, this.user)
        return
      }

      if (this.isTheSeller) {
        this.onLeave(this.booking.id, this.user)
      }
    },
    onApply (id, user) {
      var ComponentClass = Vue.extend(ApplyForm)
      var applyInstance = new ComponentClass({
        data: {
          user: user,
          booking: id,
          option: 'applying'
        }
      })
      applyInstance.$mount()
      applyInstance.$on('refresh', this.refreshPage)
      let optionsProcessContent = applyInstance.$el
      global.instanceApp.$alertSwal.modalAlert(null, optionsProcessContent, null,
        'no-padding', { showConfirmButton: false }).then((result) => { })
    },
    refreshPage () {
      this.loadBookingInfo()
    },
    onLeave (id, user) {
      let data = {
        booking: id,
        user: user
      }
      this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja devolver este booking?',
        'warning', 'Sim', 'Não', (e) => this.leaveBooking(data)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Registro devolvido com sucesso!')
        } else {
          this.$alertSwal.toast('Não foi possível devolver o registro!', 'error')
        }

        this.loadBookingInfo()
      })
    },
    async leaveBooking (id) {
      let _this = this
      _this.isLoading = true
      await BookingService.leaveBooking(id).then(res => {
        // if (res.httpCode === 200 && res.data.status === 'ok') {
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    onSaveMessage () {
      let me = this
      let formData = new FormData()
      formData.append('booking', this.booking.id)
      formData.append('message', this.newMessage)
      me.isLoading = true
      BookingService.sendMessage(formData).then(dataJson => {
        this.booking.comentarios.push(dataJson.data.data)
        me.isLoading = false
        me.newMessage = ''
      }).then(function (res) {
        let msg = `<h4>Sua mensagem foi enviada com sucesso!</h4>`
        global.instanceApp.$alertSwal.modalAlert(null, msg, 'success', '').then((result) => {})
        me.isLoading = false
        me.isPageLoading = false
      }).catch(() => {
      })
    },
    loadMergeOptions () {
      this.isLoading = true

      let booking = {
        id: this.booking.id
      }

      BookingService.getMergeOptions(booking).then(res => {
        let records = res.data.data
        if (records) {
          this.mergeOptions = records.map(x => ({
            id: x.id,
            data_criacao: x.data_criacao,
            selected: false
          }))
        }
        this.isLoading = false
      })
    },
    checkboxId (record) {
      return 'selected'.concat(record.id)
    },
    mergeSelected () {
      this.isLoading = true

      let items = this.mergeOptions.map(x => (
        x.selected ? x.id : null)
      )

      let formData = new FormData()
      formData.append('id', this.booking.id)
      formData.append('merge', JSON.stringify(items))
      BookingService.mergeBookings(formData).then(response => {
        this.loadBookingInfo()
        this.loadMergeOptions()
        this.isLoading = false
      }).catch(err => {
        if (err.code === 400) {
          this.displayErrors(err.errors)
        }
        this.isLoading = false
      })
    }
  },
  computed: {
    equipments () {
      return this.booking.equip.split(';')
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    canEdit () {
      return this.isSuperAdmin || this.isBookingAdmin
    },
    canTakeOrThrow () {
      return (this.isBookingRequester && this.isAvailable) || this.isTheSeller
    },
    isSuperAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isBookingAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_MANAGER') !== -1) {
        return true
      }
      return false
    },
    isBookingRequester () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_REQUESTER') !== -1) {
        return true
      }
      return false
    },
    isAvailable () {
      return !this.booking.id_vendedor
    },
    isTheSeller () {
      let userInfo = this.user || null
      if (userInfo && this.booking.id_vendedor && userInfo.active_employee.id_table_origin === this.booking.id_vendedor.id) {
        return true
      }
      return false
    },
    isFollowing () {
      return this.subscribers && this.user && this.subscribers.includes(this.user.id)
    },
    color () {
      return parseInt(this.booking.situacao) !== null ? this.colors[this.booking.situacao] : '#000000'
    },
    status () {
      return this.statusOptions.length ? this.statusOptions[this.booking.situacao] : '-'
    },
    operacao () {
      return this.operationOptions[this.booking.tipo_operacao - 1]
    },
    countMergeSelected () {
      return this.mergeOptions.map(merge => merge.selected)
        .reduce((prev, curr) => prev + curr, 0)
    },
    hasMessages () {
      return this.booking.comentarios && this.booking.comentarios.length > 0
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 15px;
  right: 33px;
}

tr.internal {
  background-color: #fcf8e3;
}

.royalc-btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #84754E;
  background: transparent;
}

.custom-label {
  color: grey;
}

.btn-a-color {
  color: #534B41 !important;
}
</style>
